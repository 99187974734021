'use strict'

angular.module('cb.report', [])
	.controller('SalesmenLeadsReportController', ["$scope", "$rootScope", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $rootScope, $location, NotificationService, ReportService, StaffService) {

		$scope.init = function () {
			$scope.disabledDropdown = false;
			$scope.StaffList = [];
			$scope.salesmanId = null;
			$scope.limitTo = 10;
			$scope.active = 0;
			$scope.GetStaffByRolePromise = StaffService.getStaffByRole('Sales');
			$scope.GetStaffByRolePromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				if (!$scope.viewAllPermission)
					sortUserDropdown();
			});

			$scope.drp_start = moment().subtract(1, 'months');
			$scope.drp_end = moment().add(1, 'months');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.submit();
		});

		function sortUserDropdown() {
			$scope.disabledDropdown = true;
			$scope.salesmanId = $scope.StaffList.find(staff => staff.Id == $rootScope.User.Id).Id;
		};

		$scope.$watch('salesmanId', () => {
			$scope.submit();
		});

		$scope.submit = function () {
			if ($scope.viewAllPermission && $scope.salesmanId || !$scope.viewAllPermission && $scope.salesmanId == $rootScope.User.Id) {
				$scope.GetSalesmanReportPromise = ReportService.GetSalesmanReports({ SalesmenId: $scope.salesmanId, StartDate: $scope.drp_start, EndDate: $scope.drp_end });
				$scope.GetSalesmanReportPromise.then(function (output) {
					$scope.ReportData = output.data;
					//$scope.ReportData.NotAppointedLeads = $scope.ReportData.NotAppointedLeads.concat($scope.ReportData.NewLeads);

				});
			};
		};

		$scope.changeActive = function (index) {
			$scope.active = index;
		};

		$scope.viewLead = function (LeadProfileId) {
			$location.path('/lead-details/' + LeadProfileId);
		};
	}])
	.controller('PostcodeErrorsReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.limitTo = 10;

		$scope.init = function () {
			$scope.PageNumber = { PostcodeErrors: 1 };
			$scope.GetPostcodeErrorsPromise = ReportService.GetPostcodeErrors();
			$scope.GetPostcodeErrorsPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};

		$scope.viewLead = function (LeadProfileId) {
			$location.path('/lead-details/' + LeadProfileId);
		};
	}])
	.controller('LeadAllocationReportController', ["$scope", "$rootScope", "$modal", "$location", "NotificationService", "ReportService", function ($scope, $rootScope, $modal, $location, NotificationService, ReportService) {

		$scope.init = function () {
			$scope.reportPageNumber = 1;
			$scope.reportItems = [];
			$scope.pagination = {};
			$scope.viewAllPermission = false;

			checkReportPermissions();
			$scope.drp_start = moment().startOf('month');
			$scope.drp_end = moment().endOf('month');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};

			$scope.InProgress = false;
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.RecordsPageNumber = 1;
			getReport();
		});

		$scope.$watch('reportPageNumber', () => {
			//getReport();
		});

		function checkReportPermissions() {
			// List of roles with complete permissions on this report
			var permittedRoles = ["Manager", "Account", "Marketing", "Contract", "System", "Admin", "Partnership"];
			$scope.viewAllPermission = false;
			permittedRoles.forEach((item) => {
				if ($rootScope.User.Roles != undefined && $rootScope.User.Roles != null && $rootScope.User.Roles.filter(r => r.Name.indexOf(item) > -1).length > 0) {
					$scope.viewAllPermission = true;
				}
			});
		}

		function getReport() {
			$scope.InProgress = true;
			var data = {
				Start: $scope.drp_start,
				End: $scope.drp_end,
				Pagination: {
					StartPage: $scope.reportPageNumber - 1,
					PageSize: 99999
				}
			};

			$scope.gettingReportPromise = ReportService.GetSalesmanLeadAllocation(data);
			$scope.gettingReportPromise.then(output => {
				$scope.ReportData = output.data.SalesmanAllocation;
				$scope.CompanyTotal = output.data.CompanyTotal;
				$scope.CompanyTotal.ReturnPerAppointment = $scope.CompanyTotal.Appointed > 0 ? parseFloat(($scope.CompanyTotal.SubTotal / $scope.CompanyTotal.Appointed).toFixed(2)) : 0;
				$scope.CompanyTotal.ReturnPerLead = $scope.CompanyTotal.Issued > 0 ? parseFloat(($scope.CompanyTotal.SubTotal / $scope.CompanyTotal.Issued).toFixed(2)) : 0;
				$scope.CompanyTotal.AverageOrderValue = $scope.CompanyTotal.Ordered > 0 ? parseFloat(($scope.CompanyTotal.SubTotal / $scope.CompanyTotal.Ordered).toFixed(2)) : 0;
				$scope.ReportData.forEach((item) => {
					item.ReturnPerAppointment = item.Appointed > 0 ? parseFloat((item.SubTotal / item.Appointed).toFixed(2)) : 0;
					item.ReturnPerLead = item.Issued > 0 ? parseFloat((item.SubTotal / item.Issued).toFixed(2)) : 0;
					item.AverageOrderValue = item.Ordered > 0 ? parseFloat((item.SubTotal / item.Ordered).toFixed(2)) : 0;
				});
				$scope.pagination = output.data.Pagination;
				if (!$scope.viewAllPermission)
					sortSalesView();
				$scope.InProgress = false;
			}, err => {
				NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
				$scope.InProgress = false;
			});
		};

		function sortSalesView() {
			if ($scope.ReportData != undefined && $scope.ReportData != null && $scope.ReportData.length > 0) {
				var tmp = $scope.ReportData.filter(item => item.Salesman == ($rootScope.User.FirstName + ' ' + $rootScope.User.LastName));
				const clone = structuredClone(tmp);
				tmp.unshift($scope.ReportData.reduce((previousValue, currentValue) => currentValue.ReturnPerAppointment > previousValue.ReturnPerAppointment ? currentValue : previousValue));
				tmp = tmp.filter((item, pos) => tmp.indexOf(item) == pos);
				if (Object.keys(tmp).length != 1) {
					tmp[0].Salesman = 'Current Best';
					tmp[0].Issued = '--';
					tmp[0].NotAppointed = '--';
					tmp[0].Appointed = '--';
					tmp[0].Visited = '--';
					tmp[0].Quoted = '--';
					tmp[0].Ordered = '--';
				}
				else {
					tmp[0].Salesman = 'Current Best';
					tmp = tmp.concat(clone);
				}
				$scope.ReportData = tmp;
			}
		};

		$scope.OpenLeadsModal = function (salesperson, id, datarequest) {
			var modalInstance = openModal('views/modal-templates/sales-stats-kpi-leads.html', 'SalesStatsAndKPILeadController', {
				From: 'SalesStats',
				SalesPerson: salesperson,
				SalesId: id,
				DateStart: $scope.drp_start,
				DateEnd: $scope.drp_end,
				DataRequest: datarequest,
			});
			modalInstance.result.then(function () {

			});
		};

		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};


	}])
	.controller('SalesStatsAndKPILeadController', ["$scope", "$modalInstance", "ReportService", "NotificationService", "data", function ($scope, $modalInstance, ReportService, NotificationService, data) {
		$scope.init = function () {
			$scope.leads = [];
			$scope.From = data.From;
			$scope.SalesPerson = data.SalesPerson;
			$scope.SalesId = data.SalesId
			$scope.DateStart = data.DateStart;
			$scope.DateEnd = data.DateEnd;
			$scope.DataRequest = data.DataRequest
			$scope.FetchLeads();
		};

		$scope.FetchLeads = function () {
			var input = {
				SalesPerson: $scope.SalesPerson,
				SalesId: $scope.SalesId,
				DateStart: $scope.DateStart,
				DateEnd: $scope.DateEnd
			}

			switch ($scope.DataRequest) {
				case "Leads":
					$scope.FetchLeadsPromise = ReportService.GetSalesStatsLeads(input);
					$scope.FetchLeadsPromise.then(function (output) {
						$scope.leads = output.data.leads;
					});
					break;
				case "Orders":
					$scope.FetchLeadsPromise = ReportService.GetSalesStatsOrders(input);
					$scope.FetchLeadsPromise.then(function (output) {
						$scope.leads = output.data.leads;
					});
					break;
				default:
					NotificationService.alert('Error', 'Could not identify route this has been opened from. Please contact system admin.', 'error', {});
					$scope.close;
					break;
			}
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('EnquiryCountReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {

		$scope.init = function () {
			$scope.drp_start = moment().subtract(1, 'weeks');
			$scope.drp_end = moment();
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
		};

		$scope.$watch('drp_start', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
		});

		$scope.$watch('drp_end', function () {
			$scope.displayEndDate = moment($scope.drp_end).format('DD/MM/YYYY');
			getRecords();
		});

		function getRecords() {
			$scope.ReportData = {};
			$scope.GetEnquiryRecordsPromise = ReportService.GetEnquiryRecords({ Start: $scope.drp_start, End: $scope.drp_end });
			$scope.GetEnquiryRecordsPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('UnpaidCommissionReportController', ["$scope", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $location, NotificationService, ReportService, StaffService) {
		$scope.limitTo = 20;
		$scope.FormData = {};

		$scope.init = function () {
			$scope.ReportData = {};
			$scope.GetStaffByRolePromise = StaffService.getStaffByRole('Sales');
			$scope.GetStaffByRolePromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				$scope.StaffList.splice(0, 0, { Id: "null", Name: "All Sales" })
			})
		};

		$scope.submit = function () {
			$scope.PageNumber = { UnpaidCommission: 1 };
			$scope.GetUnpaidCommissionPromise = ReportService.GetUnpaidCommissionReport($scope.FormData);
			$scope.GetUnpaidCommissionPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('KPIReportController', ["$scope", "$rootScope", "$modal", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $rootScope, $modal, $location, NotificationService, ReportService, StaffService) {
		$scope.init = function () {
			$scope.disabledDropdown = false;
			$scope.FormData = {};
			$scope.salesmanId = null;
			$scope.GetStaffByRolePromise = StaffService.getStaffByRole('Sales');
			$scope.GetStaffByRolePromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				$scope.StaffList.unshift({ Id: '00000000-0000-0000-0000-000000000000', Name: 'All Sales' });
				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales/Fitter').length > 0 ? false : true;
				if (!$scope.viewAllPermission)
					sortUserDropdown();
			});
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.drp_start = moment().startOf('month');
			$scope.drp_end = moment().endOf('month');
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.submit();
		});

		function sortUserDropdown() {
			$scope.disabledDropdown = true;
			$scope.salesmanId = $scope.StaffList.find(staff => staff.Id == $rootScope.User.Id).Id;
		};

		$scope.$watch('salesmanId', () => {
			$scope.submit();
		});

		$scope.submit = function () {
			if ((!$scope.viewAllPermission && $scope.salesmanId == $rootScope.User.Id) || $scope.viewAllPermission && $scope.salesmanId) {
				$scope.ReportData = {};
				$scope.GetKPIPromise = ReportService.GetKPIReport({ SalesmenId: $scope.salesmanId, StartDate: $scope.drp_start, EndDate: $scope.drp_end });
				$scope.GetKPIPromise.then(function (output) {
					$scope.ReportData = output.data;
				});
			};
		};

		$scope.GetCompanyMonthYear = function () {
			$scope.CompanyPreviousMonthYear = {};
			$scope.GetCompanyKPIPromise = ReportService.GetCompanyMonthYearKPI();
			$scope.GetCompanyKPIPromise.then(function (output) {
				$scope.CompanyPreviousMonthYear = output.data.Report;
			});
		}

		//$scope.OpenLeadsModal = function (salesperson, id, datarequest) {
		//	alert('??');
		//	var modalInstance = openModal('views/modal-templates/sales-stats-kpi-leads.html', 'SalesStatsAndKPILeadController', {
		//		From: 'KPI',
		//		SalesPerson: salesperson,
		//		SalesId: id,
		//		DateStart: $scope.drp_start,
		//		DateEnd: $scope.drp_end,
		//		DataRequest: datarequest,
		//	});
		//	modalInstance.result.then(function () {

		//	});
		//};

		//function openModal(modalTemplate, Controller, modalData, config) {
		//	return $modal.open({
		//		animation: true,
		//		size: 'lg',
		//		templateUrl: modalTemplate,
		//		controller: Controller,
		//		resolve: {
		//			data: function () {
		//				return modalData;
		//			},
		//			Id: function () {
		//				return modalData;
		//			},
		//			config: function () {
		//				return config
		//			}
		//		}
		//	});
		//};
	}])
	.controller('MailingROIReportController', ["$scope", "$location", "$timeout", "NotificationService", "ReportService", function ($scope, $location, $timeout, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.reportPageNumber = 1;
			$scope.pagination = {};
			$scope.ReportData = {};
			$scope.timeout = $timeout(function () { });
			$scope.NameFilter = new Date().getFullYear().toString();
			$scope.QuarterFilter = 'Q1';
		};

		$scope.$watch('reportPageNumber', () => {
			getReport();
		});

		$scope.filterItems = function () {
			$timeout.cancel($scope.timeout);
			$scope.timeout = $timeout(() => {
				if ($scope.reportPageNumber == 1)
					getReport();
				else
					$scope.reportPageNumber = 1;
			}, 500);
		};

		$scope.SelectQuarter = function (type) {
			$scope.QuarterFilter = type;
			if ($scope.reportPageNumber == 1)
				getReport();
			else
				$scope.reportPageNumber = 1;
		};

		function getReport() {
			var data = {
				Pagination: {
					StartPage: $scope.reportPageNumber - 1,
					PageSize: 15,
					Filter: $scope.NameFilter ? ($scope.QuarterFilter ? $scope.NameFilter + '' + $scope.QuarterFilter : $scope.NameFilter) : $scope.QuarterFilter ? $scope.QuarterFilter : null
				}
			};

			$scope.GetMailingROIPromise = ReportService.GetMailingROI(data);
			$scope.GetMailingROIPromise.then(function (output) {
				$scope.ReportData = output.data;
				$scope.pagination = output.data.Pagination;
			});
		};
	}])
	.controller('FitterSurveyOverviewReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.dateFormat = "d MMM, y";
		$scope.Title = "Fitter Survey Overview"

		$scope.init = function () {
			$scope.ReportData = {};
			$scope.PageNumber = { FitterSurveys: 1 };
			$scope.GetFitterSurveyPromise = ReportService.GetFitterSurveyOverview();
			$scope.GetFitterSurveyPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('FitterSurveyNotSentReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.dateFormat = "d MMM, y";
		$scope.Title = "Fitter Survey Not Sent"

		$scope.init = function () {
			$scope.ReportData = {};
			$scope.PageNumber = { FitterSurveys: 1 };
			$scope.GetFitterSurveyPromise = ReportService.GetFitterSurveyNotSent();
			$scope.GetFitterSurveyPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('InstallationAptOverdueReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.dateFormat = "d MMM, y";
		$scope.Title = "Installation Appointment Overdue"

		$scope.init = function () {
			$scope.ReportData = {};
			$scope.PageNumber = { FitterSurveys: 1 };
			$scope.GetFitterSurveyPromise = ReportService.GetInstallationAppointmentOverdue();
			$scope.GetFitterSurveyPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('AllWorkInProgressReportController', ["$scope", "$rootScope", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $rootScope, $location, NotificationService, ReportService, StaffService) {

		$scope.init = function () {
			$scope.disabledDropdown = false;
			$scope.reportItems = [];
			$scope.openPopups = {};
			$scope.staffList = [];
			$scope.selectedStaff = {};
			$scope.staffSelection = null;
			getSalesPeople();

			$scope.drp_start = moment().subtract(1, 'year');
			$scope.drp_end = moment();
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.$emit('change-false');

			$scope.$watchCollection('[drp_start, drp_end]', function () {
				$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
				$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
				getReport();
			});
		};

		function getSalesPeople() {
			$scope.gettingSalesPeoplePromise = StaffService.getAllStaff();
			$scope.gettingSalesPeoplePromise.then(output => {
				$scope.staffList = output.data.StaffList;

				//FILTER STAFFLIST FOR FITTERS ONLY
				$scope.staffList = $scope.staffList.filter(staff => staff.Roles.filter(role => role.Name == 'Fitter' || role.Name == 'Sales Person/Fitter').length > 0);
				$scope.staffList.unshift({
					Id: '00000000-0000-0000-0000-000000000000',
					FirstName: 'All',
					LastName: 'Jobs'
				});

				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				if (!$scope.viewAllPermission)
					sortUserDropdown();
				else
					$scope.staffSelection = '00000000-0000-0000-0000-000000000000';
			}, err => {
				NotificationService.alert('Staff Error', 'There was an error trying to retrieve the list of staff members.', 'error', {});
			});
		};

		function sortUserDropdown() {
			$scope.disabledDropdown = true;
			$scope.staffSelection = $scope.staffList.find(staff => staff.Id == $rootScope.User.Id).Id;

		};

		$scope.activeStaff = function (staff) {
			if ($scope.selectedStaff)
				return staff.Id == $scope.selectedStaff.Id ? 'active' : '';
		};

		$scope.selectStaff = function (staff) {
			if (staff.Id != $scope.selectedStaff.Id) {
				$scope.selectedStaff = staff;
				getReport();
			};
		};

		$scope.$watch('staffSelection', () => {
			if ($scope.staffSelection)
				$scope.selectStaff($scope.staffList.find(s => s.Id == $scope.staffSelection));
		});

		function getReport() {
			if ($scope.selectedStaff /*&& ($scope.viewAllPermission && $scope.selectedStaff.Id || !$scope.viewAllPermission && $scope.selectedStaff.Id == $rootScope.User.Id)*/) {
				var data = {
					Start: $scope.drp_start,
					End: $scope.drp_end,
					UserId: $scope.selectedStaff.Id
				};

				$scope.GetFitterSurveyPromise = ReportService.GetInstallationAppointmentOverdue(data);
				$scope.GetFitterSurveyPromise.then(output => {
					$scope.ReportData = output.data.FitterSurveys;
					// $scope.ReportData.forEach((item) => { item.MitchamDate = moment(item.MitchamDate) });
					setTimeout(() => {
						$scope.ReportData.forEach((item) => { $scope.formatMitchamDate(item, true) });
						document.getElementById('table-body').children[0].click();
						// SortFlaggedTooltips();
					}, 1);
					// setTimeout(() => {
					// 	$("#child").draggable({
					// 		cursor: "move",
					// 		containment: "#child-container"
					// 	});
					// 	if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					// 		$('#child-container').width($('#child').width() + 10);
					// 		//VIEWPORT - WIDTH OF CONTAINER TO GET GUTTER
					// 		var gutter = $('#child-container').width() - $('#parent').width();
					// 		//ADD GUTTER TO CONTAINER
					// 		$('#child-container').width($('#child-container').width() + gutter);
					// 		//SET CONTAINER -LEFT OF GUTTER
					// 		$('#child-container').css({ left: -Math.abs(gutter) });
					// 		//SET LEFT OF TABLE == GUTTER
					// 		$('#child').css({ left: gutter });
					// 	} else {
					// 		$('#child').removeAttr('id');
					// 	};
					// }, 500);
				}, err => {
					NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
				});
			};
		};

		function SortFlaggedTooltips() {
			document.querySelectorAll('.staff-leaving').forEach((elem) => {
				elem.setAttribute('data-trigger', 'hover');
				elem.setAttribute('data-tooltip', 'test');
			});
		};

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.formatMitchamDate = function (col, init) {
			if (col.MitchamDate != null && !col.MitchamDate.includes('0001-01-01') && !col.MitchamDate.includes('2001-01-01') && col.MitchamDate != 'Invalid date') {
				var date = moment(col.MitchamDate).format('DD/MM/YYYY');
				col.MitchamDate = date;
			}
			else
				col.MitchamDate = null;
			if (!init)
				$scope.submitChanges(null, col);
		};

		$scope.submitChanges = function (type, col) {
			col.MitchamDate = moment(col.MitchamDate);
			$scope.updatingJobPromise = ReportService.UpdateJobInProgress({ Job: col });
			$scope.updatingJobPromise.then(output => {
				NotificationService.alert('Job Updated', 'This job has been successfully updated.', 'success', {});
				col.MitchamDate = moment(col.MitchamDate).format('DD/MM/YYYY');
				if (col.MitchamDate == '0001-01-01T00:00:00' || col.MitchamDate == 'Invalid date')
					$scope.formatMitchamDate(col, true);
				else
					col.MitchamDate = moment(col.MitchamDate).format('DD/MM/YYYY');
			}, err => {
				NotificationService.alert('Update Error', 'There was an error when trying to update this job.', 'error', {});
				if (type == 'completion')
					col.Completed = !col.Completed;
				col.MitchamDate = moment(col.MitchamDate).format('DD/MM/YYYY');
			});
		};
	}])
	.controller('PhotosVideosReportController', ["$scope", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $location, NotificationService, ReportService, StaffService) {

		$scope.init = function () {
			$scope.staffList = [];
			$scope.selectedFitter;
			$scope.reportFilter = '';
			$scope.reportItems = [];
			$scope.pagination = {};
			getAllFitters();
		};

		function getAllFitters() {
			$scope.gettingAllFittersPromise = StaffService.getStaffByRole('Fitter');
			$scope.gettingAllFittersPromise.then(output => {
				$scope.staffList = output.data.StaffList;
			}, err => {
				NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
			});
		};

		$scope.$watch('selectedFitter', () => {
			getReport();
		});

		function getReport() {
			if ($scope.selectedFitter) {
				var data = {
					Fitter: $scope.selectedFitter,
					Pagination: {
						StartPage: 0,
						PageSize: 0,
						Filter: $scope.reportFilter || null
					}
				};

				$scope.gettingPhotosVideosReportPromise = ReportService.GetMissingMedia(data);
				$scope.gettingPhotosVideosReportPromise.then(output => {
					$scope.ReportData = output.data.Orders;
				}, err => {
					NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
				});
			};
		};

		$scope.Filter = function (filter) {
			$scope.reportFilter = filter == $scope.reportFilter ? null : filter;
			getReport();
		};
	}])
	.controller('UnresolvedCallsRemakesReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.dateFormat = "d MMM, y";

		$scope.init = function () {
			$scope.ReportData = {};
			$scope.PageNumber = { UnresolvedCallsRemakes: 1 };
			$scope.GetUnresolvedCallsRemakesPromise = ReportService.GetUnresolvedCallsRemakes();
			$scope.GetUnresolvedCallsRemakesPromise.then(function (output) {
				$scope.ReportData = output.data;
			});
		};
	}])
	.controller('LeadProviderCommissionReportController', ["$scope", "$rootScope", "$location", "$timeout", "NotificationService", "ReportService", "StaffService", "LeadProviderService", function ($scope, $rootScope, $location, $timeout, NotificationService, ReportService, StaffService, LeadProviderService) {
		$scope.init = function () {
			$scope.disabledDropdown = false;
			$scope.ReportData = {};
			$scope.StaffList = [];
			$scope.timeout = $timeout(function () { });
			$scope.NameFilter = new Date().getFullYear().toString();
			$scope.QuarterFilter = 'Q1';
			$scope.LeadProviderId = 0;
			$scope.ShowOldLPs = false;
			$scope.ShowAlreadyPaidCommission = false;
			$scope.IgnoreDateRange = false;

			$scope.drp_start = moment().subtract(6, 'months').startOf('month');
			$scope.drp_end = moment();
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};

			$scope.FetchLeadProviderList();
			//$scope.getReport();
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.getReport();
		});

		$scope.FetchLeadProviderList = function () {
			if ($scope.ShowOldLPs)
				$scope.FetchLeadProviderListPromise = LeadProviderService.getAllLeadProvidersAndOld();
			else
				$scope.FetchLeadProviderListPromise = LeadProviderService.getAllLeadProviders();
			$scope.FetchLeadProviderListPromise.then(function (output) {
				$scope.leadProviderList = output.data.LeadProviders;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.getReport = function () {
			var data = {
				SalesmenId: $scope.viewAllPermission ? null : $rootScope.User.Id,
				StartDate: $scope.drp_start,
				EndDate: $scope.drp_end,
				LeadProviderId: $scope.LeadProviderId,
				ShowAlreadyPaidCommission: $scope.ShowAlreadyPaidCommission,
				IgnoreDates: $scope.IgnoreDateRange
			};

			$scope.GetUnpaidCommissionPromise = ReportService.GetLeadProviderCommissionReport(data);
			$scope.GetUnpaidCommissionPromise.then(function (output) {
				$scope.ReportData = output.data.CommissionList;
			});
		};
	}])
	.controller('OrdersInPeriodReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {

		$scope.init = function () {
			$scope.functionExec = 0;
			$scope.selectedFilter = 'OrderDate';
			$scope.drp_start = moment.utc(moment().add(1, 'day').startOf('day').subtract(1, 'weeks')).format();
			$scope.drp_end = moment.utc(moment().endOf('day')).format();

			console.debug($scope.drp_start);
			console.debug($scope.drp_end);

			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'YYYY-MM-DD',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week'), moment().endOf('week')],
					'Last Week': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.$emit('change-false');
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			getRecords();
		});

		$scope.$watch('RecordsPageNumber', function () {
			getRecords();
		});

		$scope.$watch('selectedFilter', function () {
			getRecords();
		});

		function getRecords() {
			$scope.functionExec++;
			if ($scope.functionExec <= 2)
				return;

			var data = {
				StartDate: $scope.drp_start,
				EndDate: $scope.drp_end,
				FilterType: $scope.selectedFilter,
			};

			$scope.GetOrdersInPeriodPromise = ReportService.GetOrdersInPeriodReport(data);
			$scope.GetOrdersInPeriodPromise.then(function (output) {
				$scope.displayStartDate = output.data.Start;
				$scope.displayEndDate = output.data.End;
				$scope.ReportData = output.data.OrdersInPeriodList;
				$scope.Total = output.data.Total;

			});
		};
	}])
	.controller('SoldUnsoldLeadsReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.limitTo = 20;
			$scope.FormData = { SoldStatus: "All" };
			$scope.$emit('change-false');
		};

		//$scope.submit = function () {
		//    $scope.ReportData = {};
		//    $scope.PageNumber = { SoldUnsoldLeads: 1 };
		//    $scope.GetSoldUnsoldLeadsPromise = ReportService.GetSoldUnsoldLeadsReport($scope.FormData);
		//    $scope.GetSoldUnsoldLeadsPromise.then(function (output) {
		//        $scope.ReportData = output.data;
		//    });
		//};

		//remove these mock variables when back-end method is in place
		$scope.PageNumber = { SoldUnsoldLeads: 1 };
		$scope.Day = new Date();
		$scope.ReportData = {
			SoldUnsoldLeadsList: [
				{
					Id: 693568,
					LeadDate: $scope.Day,
					Name: "Pat Smith",
					Address: "5 Clifton Road",
					Postcode: "SO15 4GU",
					LeadSource: "South",
					LeadType: "Blind",
					BlindUse: "Yes",
					BlindType: "Full Blind",
					BroucherSent: "Yes",
					Issued: $scope.Day,
					SalesPerson: "Dan",
					LeadStatus: "User",
					Appointed: "True",
				}
			]
		}
	}])
	.controller('NewsletterManagementReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.functionExec = 0;
			$scope.selectedFilter = null;
			$scope.Pagination = {};

			$scope.drp_start = moment().startOf('month');
			$scope.drp_end = moment().endOf('month');

			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.statuses = [];
			$scope.$emit('change-false');
			getStatuses();
		};

		function getStatuses() {
			$scope.gettingStatusesPromise = ReportService.GetAllStatuses();
			$scope.gettingStatusesPromise.then(function (output) {
				$scope.statuses = output.data.LeadStatus;
			}, function (err) {
				NotificationService.alert('Statuses Error', 'There was an issue retrieving the list of statuses.', 'error', {});
			});
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.RecordsPageNumber = 1;
			getRecords();
		});

		$scope.$watch('RecordsPageNumber', function () {
			getRecords();
		});

		$scope.$watch('selectedFilter', function () {
			$scope.RecordsPageNumber = 1;
			getRecords();
		});

		function getRecords(payload) {
			$scope.functionExec++;
			if ($scope.functionExec <= 2)
				return;

			if (!payload) {
				var data = {
					Pagination: {
						StartPage: 0,
						PageSize: 0,
						Filter: $scope.selectedFilter ? $scope.selectedFilter.displayName : null
					},
					Start: $scope.drp_start,
					End: $scope.drp_end
				};
			};

			$scope.GetNewsletterManagementPromise = ReportService.GetNewsletterManagement(data || payload);
			$scope.GetNewsletterManagementPromise.then(function (output) {
				if (payload)
					$scope.exportToCSV(false, output.data.NewsletterRecords);
				else {
					$scope.ReportData = output.data.NewsletterRecords;
				};
			});
		};

		$scope.displayAllSubscribers = function () {
			$scope.drp_start = moment(19900101, 'YYYYMMDD');
			$scope.drp_end = moment();
		}

		$scope.clearFilter = function () {
			$scope.selectedFilter = null;
		};

		$scope.exportToCSV = function (entireList, response) {
			var list = [], data = [];
			if (entireList) {
				getRecords({ Pagination: { StartPage: 0, PageSize: 0, Filter: $scope.selectedFilter ? $scope.selectedFilter.displayName : null }, Start: moment('1970-01-01'), End: moment() });
			} else {
				if (!response)
					data = $scope.ReportData;
				else
					data = response;
			};

			if (data.length) {
				data.forEach(function (current) {
					delete current.$$hashKey;
					var temp = {};
					for (var k in current) {
						temp[k] = current[k];
					}
					list.push(temp);
				});
				promiseJSONToCSVConvertor(JSON.stringify(list), true);
			};
		};

		function promiseJSONToCSVConvertor(JSONData, showLabel) {
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData, CSV = '';

			if (showLabel) {
				var row = "";
				for (var index in arrData[0]) {
					index = index.replace(/([a-z])([A-Z])/g, '$1 $2');
					row += index + ',';
				};
				row = row.slice(0, -1);
				CSV += row + '\r\n';
			};

			for (var i = 0; i < arrData.length; i++) {
				var row = "";
				for (var index in arrData[i]) {
					var currentValue = String(arrData[i][index]);
					console.log(currentValue);
					currentValue = currentValue.replace("null", "");
					row += '"' + currentValue + '",';
				};
				row.slice(0, row.length - 1);
				CSV += row + '\r\n';
			};

			if (CSV == '')
				return;

			downloadFile('Newsletter_Management_Export.csv', 'text/csv', CSV)
		};

		function downloadFile(filename, mimeType, csvContent) {
			filename = filename.replace(/['"]+/g, '');
			var blob = new Blob([csvContent]);
			var needsClick = true;

			if (navigator.appVersion.toString().indexOf('.NET') > 0)
				window.navigator.msSaveBlob(blob, filename);

			else if (window.URL) {
				var evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", null, null);

				var a = document.createElement("a");
				document.body.appendChild(a);
				a.style = "display: none";
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = filename;
				a.click();
				//window.URL.revokeObjectURL(url);
			} else {
				if (document.createEvent) {
					var link = document.createElement("a");
					link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
					link.setAttribute("download", filename);
					var e = document.createEvent('MouseEvents');
					e.initEvent('click', true, true);
					needsClick = link.dispatchEvent(e);
				};

				if (needsClick)
					window.navigator.msSaveBlob(blob, filename);
			};
		};
	}])
	.controller('OrderSummaryReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.limitTo = 20;
			$scope.FormData = {};

			$scope.Today = new Date();
			$scope.openPopups = {};
			$scope.$emit('change-false');
		};

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		//$scope.submit = function () {
		//    $scope.ReportData = {};
		//    $scope.PageNumber = { OrderSummary: 1 };
		//    $scope.GetOrderSummaryPromise = ReportService.GetOrderSummaryReport($scope.FormData);
		//    $scope.GetOrderSummaryPromise.then(function (output) {
		//        $scope.ReportData = output.data;
		//    });
		//};

		//remove these mock variables when back-end method is in place
		$scope.PageNumber = { OrderSummary: 1 };
		$scope.ReportData = {
			OrderSummaryList: [
				{
					SalesPerson: "Dan",
					Area: "South-East",
					Orders: "Many",
					Value: "200"
				}
			]
		}
	}])
	.controller('GetOrdersInProgressReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.limitTo = 10;
			$scope.$emit('change-false');
		};

		//$scope.init = function () {
		//    $scope.ReportData = {};
		//    $scope.PageNumber = { OrdersInProgress: 1 };
		//    $scope.GetOrdersInProgressPromise = ReportService.GetOrdersInProgressReport();
		//    $scope.GetOrdersInProgressPromise.then(function (output) {
		//        $scope.ReportData = output.data;
		//    });
		//};

		//remove these mock variables when back-end method is in place
		$scope.PageNumber = { OrdersInProgress: 1 };
		$scope.Day = new Date();
		$scope.ReportData = {
			OrdersInProgressList: [
				{
					Id: 693568,
					Created: $scope.Day,
					Name: "Pat Smith",
					Address: "5 Clifton Road",
					Postcode: "SO15 4GU",
					Phone: "02380603368",
					Value: "200",
					SalesPerson: "Dan",
					Fitter: "Bob",
					Surveyed: $scope.Day,
					SupplierDelivery: $scope.Day,
					JobComplete: $scope.Day,
					PaymentCompleted: $scope.Day,
				}
			]
		}
	}])
	.controller('OrderByActionReportController', ["$scope", "$location", "NotificationService", "ReportService", function ($scope, $location, NotificationService, ReportService) {
		$scope.init = function () {
			$scope.limitTo = 20;
			$scope.FormData = {};
			$scope.$emit('change-false');
		};

		//$scope.init = function () {
		//    $scope.GetOrderActionsPromise = ReportService.GetOrderActions();
		//    $scope.GetOrderActionsPromise.then(function (output) {
		//        $scope.Actions = output.data.ActionCodeList;
		//    });
		//};

		//$scope.submit = function () {
		//    $scope.ReportData = {};
		//    $scope.PageNumber = { OrderByAction: 1 };
		//    $scope.GetOrderByActionPromise = ReportService.GetOrderByActionReport($scope.FormData);
		//    $scope.GetOrderByActionPromise.then(function (output) {
		//        $scope.ReportData = output.data;
		//    });
		//};

		//remove these mock variables when back-end method is in place
		$scope.PageNumber = { OrderByAction: 1 };
		$scope.Day = new Date();
		$scope.ReportData = {
			OrderByActionList: [
				{
					SaleOrderId: 693568,
					LeadName: "Name",
					Company: "Company",
					Address: "5 Clifton Road",
					Postcode: "SO15 4GU",
					HomePhone: "02380603368",
					Email: "company@company.com",
					OrderValue: "200",
					SalesPerson: "Dan",
					Fitter: "Bob",
					Surveyed: $scope.Day,
					Delivered: $scope.Day,
					Installation: $scope.Day,
					Completion: $scope.Day,
					Paid: $scope.Day,
					OrderDate: $scope.Day
				}
			]
		}
	}])
	.controller('ServiceCallsOutstandingReportController', ["$scope", "$routeParams", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $routeParams, $location, NotificationService, ReportService, StaffService) {
		$scope.init = function () {
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.drp_start = moment().subtract(4, 'weeks');
			$scope.drp_end = moment();

			$scope.Type = $routeParams.type.replace(/\-/g, ' ');
			if ($scope.Type == 'service') {
				$scope.Title = 'Service Calls Outstanding'
				$scope.reportName = 'Service Calls';
			}
			else if ($scope.Type == 'remake') {
				$scope.Title = 'Remakes Outstanding';
				$scope.reportName = 'Remakes';
			}

			$scope.ServiceCalls = [];
			$scope.$emit('change-false');


			$scope.staffSelection = '00000000-0000-0000-0000-000000000000';
			$scope.gettingSalesPeoplePromise = StaffService.getAllStaff();
			$scope.gettingSalesPeoplePromise.then(output => {
				$scope.staffList = output.data.StaffList;

				//FILTER STAFFLIST FOR FITTERS ONLY
				$scope.staffList = $scope.staffList.filter(staff => staff.Roles.filter(role => role.Name == 'Fitter' || role.Name == 'Sales Person/Fitter').length > 0);
				$scope.staffList.unshift({
					Id: '00000000-0000-0000-0000-000000000000',
					FirstName: 'All',
					LastName: 'Fitters'
				});

			}, err => {
				NotificationService.alert('Staff Error', 'There was an error trying to retrieve the list of staff members.', 'error', {});
			});
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			if ($scope.drp_start != undefined && $scope.drp_end != undefined)
				$scope.listServiceCalls();
		});

		$scope.listServiceCalls = function () {
			var data = {
				Type: $routeParams.type.replace(/\-/g, ' '),
				start: $scope.drp_start,
				end: $scope.drp_end,
				Fitter: $scope.staffSelection
			};
			$scope.gettingServiceCallsPromise = ReportService.GetServiceCallsOutstanding(data);
			$scope.gettingServiceCallsPromise.then(function (output) {
				$scope.ServiceCalls = output.data.Calls;
				$scope.ServiceCalls.forEach(call => {
					var string, arr = ['Customer', 'Fitter'];
					arr.forEach(key => {
						string = '';
						if (call[key].Title)
							string = call[key].Title
						if (call[key].FirstName)
							string += ' ' + call[key].FirstName
						if (call[key].LastName)
							string += ' ' + call[key].LastName
						call[key] = string;
					});
				});
			}, function (error) {
				NotificationService.alert($scope.reportName, 'There was an error retrieving the list of outstanding ' + $scope.reportName + '.', 'error', {});
			});
		};


	}])
	.controller('ReportPagingController', ["$scope", function ($scope) {

		$scope.decrement = function () {
			$scope.pageNumber--;
		};

		$scope.increment = function () {
			$scope.pageNumber++;
		};

		$scope.HasPageBack = function () {
			return $scope.pageNumber != 1 ? true : false;
		}

		$scope.HasPageForward = function () {
			return $scope.pageNumber * $scope.listLimit < $scope.listLength ? true : false;
		}
	}])

	.controller('EnquirySourceAnalysisController', ["$scope", "$location", "NotificationService", "ReportService", "StaffService", function ($scope, $location, NotificationService, ReportService, StaffService) {

		$scope.init = function () {
			$scope.reportItems = [];
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};
			$scope.drp_start = moment().subtract(1, 'weeks');
			$scope.drp_end = moment();
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			getReport();
		});

		function getReport() {
			if ($scope.drp_start && $scope.drp_end) {
				var data = {
					Start: $scope.drp_start,
					End: $scope.drp_end,
				};

				$scope.gettingReportPromise = ReportService.GetEnquirySourceAnalysisReport(data);
				$scope.gettingReportPromise.then(output => {
					$scope.ReportData = output.data.EnquirySourceAnalysisList;
				}, err => {
					NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
				});
			};
		};
	}])

	//REUSABLE CONTROLLER FOR THE INSTALLER CURRENT WORK REPORTS
	.controller('InstallerCurrentWorkController', ["$scope", "$rootScope", "$routeParams", "NotificationService", "ReportService", "StaffService", "$timeout", function ($scope, $rootScope, $routeParams, NotificationService, ReportService, StaffService, $timeout) {

		$scope.init = function () {
			$scope.drp_start = moment().subtract(1, 'months');
			$scope.drp_end = moment().add(1, 'months');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};

			$scope.disabledDropdown = false;
			$scope.Type = $routeParams.type.replace(/\-/g, ' ');
			$scope.staffList = [];
			$scope.reportPageNumber = 0;
			$scope.reportItems = [];
			$scope.Fields = [];
			$scope.pagination = {};
			getAllFitters();
			sortFields();
		};

		$scope.$watchCollection('[drp_start, drp_end, selectedFitter, reportPageNumber]', function (newValue, oldValue) {
			var timeout = null;
			$timeout.cancel(timeout);
			timeout = $timeout(function () {
				$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
				$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');

				if (newValue[3] == 0)
					$scope.reportPageNumber = 1;
				else
					getReport();

			}, 500);
		});

		function getAllFitters() {
			$scope.gettingAllFittersPromise = StaffService.getStaffByRole('Fitter');	//CHANGE TO LOOK FOR BOTH ROLES
			$scope.gettingAllFittersPromise.then(output => {
				$scope.staffList = output.data.StaffList;
				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Fitter' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				if (!$scope.viewAllPermission)
					sortUserDropdown();
			}, err => {
				NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
			});
		};

		function sortUserDropdown() {
			$scope.disabledDropdown = true;
			$scope.selectedFitter = $scope.staffList.find(staff => staff.Id == $rootScope.User.Id).Id;
		};

		function sortFields(type) {
			switch (type) {
				case 'NewJobs':
					$scope.Fields = ['SoldDate', 'Ref', 'Customer', 'Postcode', 'InstallationDue', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'Survey':
					$scope.Fields = ['SurveyDate', 'Ref', 'Customer', 'Postcode', 'InstallationDue', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'Install':
					$scope.Fields = ['InstallDate', 'Ref', 'Customer', 'Postcode', 'InstallationDue', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'Remakes':
					$scope.Fields = ['ReturnVisit', 'Ref', 'Customer', 'Postcode', 'InstallDate', 'RemakeReason', 'NumberRemakes', 'RoofBlindRemakes'];
					break;
				case 'ServiceCall':
					$scope.Fields = ['DateReported', 'ReturnDate', 'Ref', 'Customer', 'Postcode', 'InstallDate', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'ChargeableServiceCall':
					$scope.Fields = ['DateRequested', 'ReturnDate', 'Ref', 'Customer', 'Postcode', 'InstallDate', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'MaintenanceVisits':
					$scope.Fields = ['ReturnDate', 'Ref', 'Customer', 'Postcode', 'InstallDate', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds'];
					break;
				case 'CustomerPhoneCallRequest':
					$scope.Fields = ['DateReported', 'Ref', 'Customer', 'Postcode', 'InstallDate', 'RoofBlinds', 'SideBlinds', 'HouseBlinds', 'LanternBlinds', 'HomePhone', 'Mobile', 'Email'];
					break;
			}
		};

		function getReport() {
			if (($scope.viewAllPermission && $scope.selectedFitter || !$scope.viewAllPermission && $scope.selectedFitter == $rootScope.User.Id) && $rootScope.User.Id) {
				$scope.reportItems = [];

				var type = $scope.Type.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				}).replace(/\s/g, '');
				sortFields(type);

				var data = {
					Type: type,
					FitterId: $scope.selectedFitter,
					Start: $scope.drp_start,
					End: $scope.drp_end,
					Pagination: {
						StartPage: $scope.reportPageNumber - 1,
						PageSize: 15
					}
				};

				$scope.gettingReportPromise = ReportService.GetInstallerCurrentWork(data);
				$scope.gettingReportPromise.then(output => {
					$scope.reportItems = output.data.Orders;
					$scope.pagination = output.data.Pagination;
					console.log(output.data.Orders);
					$scope.reportItems.forEach(SortDates);
					console.log($scope.reportItems);
					setTimeout(() => { SortEmptyFields() }, 50);
				}, err => {
					NotificationService.alert('Report Error', 'There was an issue retrieving the report at this time.', 'error', {});
				});
			};
		};

		function SortDates(item) {
			if ('SoldDate' in item)
				item.SoldDate = moment(item.SoldDate).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.SoldDate).format('DD/MM/YYYY');
			if ('InstallationDue' in item)
				item.InstallationDue = moment(item.InstallationDue).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.InstallationDue).format('DD/MM/YYYY');
			if ('SurveyDate' in item)
				item.SurveyDate = moment(item.SurveyDate).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.SurveyDate).format('DD/MM/YYYY');
			if ('InstallDate' in item)
				item.InstallDate = moment(item.InstallDate).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.InstallDate).format('DD/MM/YYYY');
			if ('ReturnVisit' in item)
				item.ReturnVisit = moment(item.ReturnVisit).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.ReturnVisit).format('DD/MM/YYYY');
			if ('DateReported' in item)
				item.DateReported = moment(item.DateReported).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.DateReported).format('DD/MM/YYYY');
			if ('DateRequested' in item)
				item.DateRequested = moment(item.DateRequested).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.DateRequested).format('DD/MM/YYYY');
			if ('ReturnDate' in item)
				item.ReturnDate = moment(item.ReturnDate).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(item.ReturnDate).format('DD/MM/YYYY');
		}


		$scope.findValue = function (obj, key) {
			if (key == 'Ref') {
				if (!document.getElementById(obj[key]).children.length) {
					var anchor = document.createElement('a');
					anchor.setAttribute('href', '#/sales-order/' + obj[key]);
					anchor.innerHTML = obj[key];
					document.getElementById(obj[key]).appendChild(anchor);
				};
			} else {
				switch (typeof obj[key]) {
					case 'string':
						if (isNaN(new Date(obj[key]).getTime()))
							return obj[key] || 'empty';
						else
							return moment(obj[key]).format('DD/MM/YYYY') == '01/01/0001' ? 'empty' : moment(obj[key]).format('DD/MM/YYYY');
						break;
					default:
						return obj[key] || 'empty';
						break;
				};
			};
		};

		function SortEmptyFields() {
			document.querySelectorAll('td').forEach((elem) => {
				if (elem.innerHTML == 'empty')
					elem.classList.add('field-empty');
			});
		};
	}])

	// Generic Report Controller that will be used for exporting data
	.controller('ReportController', ["$scope", function ($scope) {

		$scope.reportResultsPage = 1;
		$scope.reportResultsNextPage = false;

		$scope.reset = function () {
			delete $scope.displayReportResults;
		};

		$scope.search = function () {
			$scope.displayReportResults = true;
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};


		$scope.generateCSV = function () {
			var data = [
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' },
				{ Col1: 'Test', Col2: 'Test', Col3: 'Test' }
			];

			JSONToCSVConvertor(data, "Test Report", true);
		};

		function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

			var CSV = '';
			//Set Report title in first row or line

			CSV += ReportTitle + '\r\n';

			//This condition will generate the Label/Header
			if (ShowLabel) {
				var row = "";

				//This loop will extract the label from 1st index of on array
				for (var index in arrData[0]) {

					//Now convert each value to string and comma-seprated
					row += index + ',';
				}

				row = row.slice(0, -1);

				//append Label row with line break
				CSV += row + '\r\n';
			}

			//1st loop is to extract each row
			for (var i = 0; i < arrData.length; i++) {
				var row = "";

				//2nd loop will extract each column and convert it in string comma-seprated
				for (var index in arrData[i]) {
					row += '"' + arrData[i][index] + '",';
				}

				row.slice(0, row.length - 1);

				//add a line break after each row
				CSV += row + '\r\n';
			}

			if (CSV == '') {
				alert("Invalid data");
				return;
			}

			//Generate a file name
			var fileName = "CB-Report_";
			//this will remove the blank-spaces from the title and replace it with an underscore
			fileName += ReportTitle.replace(/ /g, "_");

			//Initialize file format you want csv or xls
			var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

			// Now the little tricky part.
			// you can use either>> window.open(uri);
			// but this will not work in some browsers
			// or you will not get the correct file extension

			//this trick will generate a temp <a /> tag
			var link = document.createElement("a");
			link.href = uri;

			//set the visibility hidden so it will not effect on your web-layout

			link.download = fileName + ".csv";

			//this part will append the anchor tag and remove it after automatic click
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}])

	//printing controller for printing all old reports
	.controller('PrintingController', ["$scope", "PrintingService", function ($scope, PrintingService) {

		$scope.init = function () {
			$scope.ReportForPreview = PrintingService.ReportForPreview;
		};

		$scope.close = function () {
			$scope.$emit('change-false');
		};

		$scope.printReport = function () {
			window.print();
		};

	}])

	.controller('MapController', ["$scope", "$rootScope", "PickListService", "MaintenanceService", "LeadProviderService", "ReportService", "LeadProfileService", "StaffService", function ($scope, $rootScope, PickListService, MaintenanceService, LeadProviderService, ReportService, LeadProfileService, StaffService) {

		$scope.init = function () {
			$scope.getPickLists();
			$scope.recieveDashboardData();

			$scope.lastMarkerFocused = 0;
			$scope.map = {};
			$scope.markers = {};
			$scope.initMap();
			$scope.mapPageNum = 0;
		};

		$scope.getPickLists = function () {
			$scope.CollectionPromise = PickListService.getPickListByPickListName(['ddmProduct', 'BlindUse']);
			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
			}, function (error) { });
			//customer search lead status
			$scope.GetAllStatusPromise = MaintenanceService.getAllStatuses();
			$scope.GetAllStatusPromise.then(function (output) {
				$scope.LeadStatusData = output.data.StatusList;
			});
			$scope.GetAllStatusGroupPromise = MaintenanceService.getAllStatuseGroups();
			$scope.GetAllStatusGroupPromise.then(function (output) {
				$scope.LeadStatusGroupData = output.data.StatusGroupList;
			}, function (error) {
				NotificationService.alert('Lead Status Group Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});
			$scope.GetLeadProviderPromise = LeadProviderService.getAllLeadProviders();
			$scope.GetLeadProviderPromise.then(function (output) {
				$scope.LeadProviderData = output.data.LeadProviders;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});
		}

		//dashboard tab
		$scope.recieveDashboardData = function () {
			if ($rootScope.onLineFlag == true) {
				var reportsData = {
					SalesmenId: $rootScope.User.Id,
					StartDate: moment().subtract(6, 'month'),
					EndDate: moment().add(1, 'week')
				};
				$scope.ReportDataPromise = ReportService.GetSalesmanReports(reportsData);
				$scope.ReportDataPromise.then(function (output) {
					$scope.ReportData = output.data;
					$scope.markerSetup();
				});
			}
			else {
				// TO BE IMPLEMENTED -- OFFLINE SYNC NEEDS TO DISPLAY SAVED RECORDS.
			}
		}

		$scope.changeActive = function (index) {
			$scope.active = index;
		};

		$scope.ChangeDashboardTab = function (index) {
			$scope.dashboardTab = index;
		};

		$scope.viewLead = function (LeadProfileId) {
			$location.path('/lead-details/' + LeadProfileId);
		};

		$scope.selectMarker = function (Id) {
			if (Id == 0) {
				NotificationService.alert('Error', 'There was an error requesting this marker.', 'error', {});
			}
			else {
				if ($scope.markers[Id].focus == false)
					$scope.markers[Id].focus = true;
				else
					$scope.markers[Id].focus = false;
			}
			if ($scope.lastMarkerFocused != Id && $scope.lastMarkerFocused != 0)
				$scope.markers[$scope.lastMarkerFocused].focus = false;
			$scope.lastMarkerFocused = Id;
		};

		$scope.initMap = function () {
			angular.extend($scope, {
				london: {
					lat: 51.505,
					lng: -0.09,
					zoom: 6
				},
				defaults: {
					tileLayer: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
				}
			});
		};

		$scope.getLatLng = function () {
			var Ids = [];
			for (i = 0; i < $scope.AllLeads.length; i++) {
				Ids.push($scope.AllLeads[i].Id);
			}
			var Data = {
				ExternalRef: Ids
			};
			$scope.getLatLngPromise = LeadProfileService.getLatLng(Data);
			$scope.getLatLngPromise.then(function (output) {
				$scope.Addresses = output.data.Addresses;
				$scope.CreateMarkers();
			});
		}

		$scope.markerSetup = function () {
			//group all appointments/assigned leads into one.
			$scope.AllLeads = [];
			if ($scope.ReportData.NewLeads.length != 0)
				$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.NewLeads);
			if ($scope.ReportData.NotAppointedLeads.length != 0)
				$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.NotAppointedLeads);
			if ($scope.ReportData.AppointedLeads.length != 0)
				$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.AppointedLeads);
			if ($scope.ReportData.QuotedLeads.length != 0)
				$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.QuotedLeads);
			//if ($scope.ReportData.Unsold.length != 0)
			//	$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Unsold);
			//if ($scope.ReportData.Sold.length != 0)
			//	$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Sold);
			for (i = 0; i < $scope.ReportData.NewLeads.length; i++) {
				if ($rootScope.onLineFlag == false) {
				}
				else {
					MapPaginatedTableValues();
					$scope.getLatLng();//get addresses
				}
			}
		}

		$scope.MapFilter = function () {
			$scope.lastMarkerFocused = 0;
			$scope.markers = {};
			$scope.AllLeads = [];
			if ($scope.map.filter != "" && $scope.map.filter != null && $scope.map.filter != undefined && $scope.map.filter != "All") {
				$scope.AllLeads = $scope.ReportData[$scope.map.filter];
			}
			else {
				if ($scope.ReportData.NewLeads.length != 0)
					$scope.AllLeads = $scope.ReportData.NewLeads;
				if ($scope.ReportData.NotAppointedLeads.length != 0)
					if ($scope.AllLeads.length == 0)
						$scopeAllLeads = $scope.ReportData.NotAppointedLeads
					else
						$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.NotAppointedLeads);
				if ($scope.ReportData.AppointedLeads.length != 0)
					if ($scope.AllLeads.length == 0)
						$scopeAllLeads = $scope.ReportData.AppointedLeads
					else
						$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.AppointedLeads);
				if ($scope.ReportData.QuotedLeads.length != 0)
					if ($scope.AllLeads.length == 0)
						$scopeAllLeads = $scope.ReportData.QuotedLeads
					else
						$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.QuotedLeads);
				//if ($scope.ReportData.Unsold.length != 0)
				//	if ($scope.AllLeads.length == 0)
				//		$scopeAllLeads = $scope.ReportData.Unsold
				//	else
				//		$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Unsold);
				//if ($scope.ReportData.Sold.length != 0)
				//	if ($scope.AllLeads.length == 0)
				//		$scopeAllLeads = $scope.ReportData.Sold
				//	else
				//		$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Sold);
			}
			MapPaginatedTableValues();
			$scope.CreateMarkers();
		}

		$scope.CreateMarkers = function () {
			for (i = 0; i < $scope.AllLeads.length; i++) {
				//create marker.
				var id = $scope.AllLeads[i].Id;
				var address = $scope.Addresses.find(ad => ad.Postcode === $scope.AllLeads[i].Postcode);
				if (address.Lat != null && address.Lng != null && address.Lat != '' && address.Lng != '') {
					$scope.markers[id] = {
						lat: parseFloat(address.Lat),
						lng: parseFloat(address.Lng),
						focus: false,
						message: "<dt>Name: " + $scope.AllLeads[i].LeadName + "</dt>"
							+ "<dt>Address : " + address.PrimaryStreet + "</dt>"
							+ "<dt>Town/City : " + address.Town + "</dt>"
							+ "<dt>County : " + address.County + "</dt>"
							+ "<dt>Postcode: " + address.Postcode + "</dt>"
							+ "<dt>PhoneNumber: " + "</dt>"
							+ "<dt>Appointment: " + "</dt>"
							+ "<dt>Product Details: " + "</dt>"
							+ "<dt>Order: <a href='/app/#/lead-details/" + id + "'>" + id + "</a>" + "</dt>",
						icon: {
							//iconUrl: "",
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
						}
					};
					switch ($scope.AllLeads[i].SaleStatus) {
						case "New Lead":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/assets/img/green-marker-icon.png";
							break;
						case "Not Appointed":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/assets/img/orange-marker-icon.png";
							break;
						case "Appointed":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/assets/img/purple-marker-icon.png";
							break;
						case "Quoted: Hot":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/assets/img/red-marker-icon.png";
							break;
						case "Unsold":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/assets/img/sky-blue-marker-icon.png";
							break;
						case "Sold":
							$scope.markers[id].icon.iconUrl = "http://localhost:13353/app/bower_components/leaflet/dist/images/marker-icon.png";
							break;
						default:
							break;

					}
					$scope.AllLeads[i].AddressLookupFailed = false;
				}
				else { //postcode lookup failed as this address has null values for lat/lng. Disable highlight button.
					$scope.AllLeads[i].AddressLookupFailed = true;
				}
			}
		}

		function MapPaginatedTableValues() {
			$scope.mapDisplayedItems = [];
			$scope.mapPageNum = 0;
			if ($scope.AllLeads.length < 6) {
				for (i = 0; i < $scope.AllLeads.length; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}
			else {
				for (i = 0; i < 6; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}
		}

		$scope.MapIncrementPage = function () {
			if ($scope.mapPageNum + 1 < $scope.AllLeads.length / 6) {
				$scope.mapPageNum++;
				var remainder = $scope.AllLeads.length % 6;
				$scope.mapDisplayedItems = [];
				if ($scope.AllLeads.length < 6) {
					for (i = 0; i < $scope.AllLeads.length; i++) {
						$scope.mapDisplayedItems.push($scope.AllLeads[i])
					}
				}
				else if (remainder != 0 && $scope.AllLeads.length < ($scope.mapPageNum + 1) * 6) { //this means this is the final page which has less than 6 results.
					for (i = 0; i < remainder; i++) {
						var leadId = ($scope.mapPageNum * 6) + i;
						$scope.mapDisplayedItems.push($scope.AllLeads[leadId])
					}
				}
				else {
					for (i = $scope.mapPageNum * 6; i < ($scope.mapPageNum * 6) + 6; i++) {
						$scope.mapDisplayedItems.push($scope.AllLeads[i])
					}
				}
			}
		}

		$scope.MapDecrementPage = function () {
			if ($scope.mapPageNum >= 0) {
				$scope.mapPageNum--;
				$scope.mapDisplayedItems = [];
				for (i = $scope.mapPageNum * 6; i < ($scope.mapPageNum * 6) + 6; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}
		}
	}])

	.controller('SurveyUploadReportController', ["$scope", "$routeParams", "NotificationService", "ReportService", "StaffService", function ($scope, $routeParams, NotificationService, ReportService, StaffService) {
		$scope.init = function () {
			$scope.SurveyUploads = [];
			$scope.$emit('change-false');

			$scope.drp_start = moment().subtract(1, 'months');
			$scope.drp_end = moment().add(1, 'months');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};

			$scope.staffSelection = '00000000-0000-0000-0000-000000000000';
			$scope.gettingFittersPromise = StaffService.getStaffByRole('Fitter');
			$scope.gettingFittersPromise.then(output => {
				$scope.staffList = output.data.StaffList;

				/*//FILTER STAFFLIST FOR FITTERS ONLY
				$scope.staffList = $scope.staffList.filter(staff => staff.Roles.filter(role => role.Name == 'Fitter' || role.Name == 'Sales Person/Fitter').length > 0);
				$scope.staffList.unshift({
					Id: '00000000-0000-0000-0000-000000000000',
					FirstName: 'All',
					LastName: 'Fitters'
				});*/

			}, err => {
				NotificationService.alert('Staff Error', 'There was an error trying to retrieve the list of staff members.', 'error', {});
			});
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.listSurveyUpload();
		});

		$scope.listSurveyUpload = function () {
			if ($scope.drp_start != null && $scope.drp_end != null && $scope.staffSelection != null) {
				var data = {
					Start: $scope.drp_start,
					End: $scope.drp_end,
					Fitter: $scope.staffSelection
				};
				$scope.gettingSurveyUploadPromise = ReportService.GetSurveyUpload(data);
				$scope.gettingSurveyUploadPromise.then(function (output) {
					$scope.SurveyUploads = output.data.SurveyUploadList;
				}, function (error) {
					NotificationService.alert('Survey Uploads', 'There was an error retrieving the list of survey uploads.', 'error', {});
				});
			}
		};


	}])

	.controller('SurveyVideosReportController', ["$scope", "$routeParams", "NotificationService", "ReportService", "StaffService", function ($scope, $routeParams, NotificationService, ReportService, StaffService) {
		$scope.init = function () {
			$scope.SurveyVideos = [];
			$scope.$emit('change-false');

			$scope.drp_start = moment().subtract(1, 'months');
			$scope.drp_end = moment().add(1, 'months');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};

			$scope.staffSelection = '00000000-0000-0000-0000-000000000000';
			$scope.gettingFittersPromise = StaffService.getStaffByRole('Fitter');
			$scope.gettingFittersPromise.then(output => {
				$scope.staffList = output.data.StaffList;
			}, err => {
				NotificationService.alert('Staff Error', 'There was an error trying to retrieve the list of staff members.', 'error', {});
			});
		};

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			$scope.listSurveyVideos();
		});

		$scope.listSurveyVideos = function () {
			if ($scope.drp_start != null && $scope.drp_end != null && $scope.staffSelection != null) {
				var data = {
					Start: $scope.drp_start,
					End: $scope.drp_end,
					Fitter: $scope.staffSelection
				};
				$scope.gettingSurveyVideosPromise = ReportService.GetSurveyVideos(data);
				$scope.gettingSurveyVideosPromise.then(function (output) {
					$scope.SurveyVideos = output.data.SurveyVideosList;
				}, function (error) {
					NotificationService.alert('Survey Videos', 'There was an error retrieving the list of survey videos.', 'error', {});
				});
			}
		};
	}])

	.controller('MonthlySaleReportController', ["$scope", "$routeParams", "$rootScope", "NotificationService", "ReportService", "StaffService", "PickListService", function ($scope, $routeParams, $rootScope, NotificationService, ReportService, StaffService, PickListService) {
		$scope.init = function () {
			$scope.SalesPerson = null;
			$scope.PreviousSales = null;
			$scope.LeadProvider = null;
			$scope.LeadProviderList = [];
			$scope.LeadSource = null;
			$scope.SourceDetailId = null;
			$scope.SourceDetail = null;
			$scope.LastLeadSource = null;
			var currentYear = moment().year();
			var range = [];

			for (var i = 1; i < 20; i++) {
				range.push({
					label: currentYear - i,
					value: currentYear - i
				});
			}

			$scope.years = range;
			$scope.CompareYear = $scope.years[0].value;
			$scope.GetStaffByRolePromise = StaffService.getStaffByRole('Sales');
			$scope.GetStaffByRolePromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				$scope.StaffList.unshift({
					Id: '00000000-0000-0000-0000-000000000000',
					Name: 'All Sales'
				});
			});
			$scope.CollectionPromise = PickListService.getPickListByPickListName(['LeadSource', 'leadCode']);
			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
			}, function (error) { });
			$scope.drp_start = moment().startOf('month');
			$scope.drp_end = moment().endOf('month');
			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true,
				ranges: {
					'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
					'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
				}
			};


		}

		$scope.changeSelectedItem = function () {
			if ($scope.LastLeadSource != $scope.LeadSource)
				$scope.SourceDetail = null;
			$scope.LastLeadSource = $scope.LeadSource;
		}

		$scope.$watchCollection('[drp_start, drp_end, SalesPerson, LeadProvider, LeadSource, CompareYear, SourceDetail]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			console.log($scope.displayStartDate);
			console.log($scope.drp_start);
			
			if ($scope.SalesPerson != null) {
				if ($scope.PreviousSales != $scope.SalesPerson) {
					$scope.PreviousSales = $scope.SalesPerson;
					$scope.LeadProvider = null;
					$scope.LeadProviderList = null;
					$scope.SourceDetailId = null;
					$scope.SourceDetail = null;
				}
				fetchStats();
			}
		});

		function fetchStats() {
			var data = {
				SalesPersonId: $scope.SalesPerson,
				LeadProviderId: $scope.LeadProvider,
				LeadSourceId: $scope.LeadSource,
				SourceDetailId: $scope.SourceDetail,
				StartDate: $scope.drp_start,
				EndDate: $scope.drp_end,
				Year: $scope.CompareYear
			}
			//alert(JSON.stringify(data));
			$scope.GetSalesMonthlyReportPromise = ReportService.GetSalesMonthlyReport(data);
			$scope.GetSalesMonthlyReportPromise.then(function (output) {
				$scope.ReportData = output.data.MonthlyReport;
				$scope.OrderTotal = output.data.OrderTotal;
				$scope.GrossValueTotal = output.data.GrossValueTotal;
				$scope.LeadProviderList = output.data.LeadProviders;
			}, function (error) {
				NotificationService.alert('Error fetching Sales Monthly Report', 'There was an error fetching the Sales Monthly Report', 'error', {});
			});

			$scope.GetSalesComparativeMonthlyReportPromise = ReportService.GetSalesComparativeMonthlyReport(data);
			$scope.GetSalesComparativeMonthlyReportPromise.then(function (output) {
				$scope.ComparativeReportData = output.data;

			}, function (error) {
				NotificationService.alert('Error fetching Sales Comparison Monthly Report', 'There was an error fetching the Sales ComparisionMonthly Report', 'error', {});
			});
		}
	}])

	.controller('TimeSheetReportController', ["$scope", "$routeParams", "$rootScope", "NotificationService", "ReportService", "StaffService", "PickListService", "$modal", function ($scope, $routeParams, $rootScope, NotificationService, ReportService, StaffService, PickListService, $modal) {
		$scope.init = function () {
			$scope.FitterId;
			$scope.FitterList = [];
			$scope.TimeSheetReport = [];
			$scope.Year = moment().year();
			$scope.Week = moment().week();

			//staff picklist
			$scope.GetStaffByRolePromise = StaffService.getStaffByRole('Fitter');
			$scope.GetStaffByRolePromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				var newStaff = {
					Id: '00000000-0000-0000-0000-000000000000',
					Name: 'All Fitters'
				}
				$scope.StaffList.unshift(newStaff);
			});

			$scope.SetWeek();
		}


		$scope.SetWeek = function () {
			if ($scope.Week <= 52) {
				var momentYear = moment().set('year', $scope.Year);
				$scope.timeStart = moment(momentYear).week($scope.Week).startOf('week');
				$scope.timeEnd = moment(momentYear).week($scope.Week).endOf('Week');
				$scope.FetchTimeSheetReport();
			}
			else
				NotificationService.alert("Week Error", "You cannot search for an impossible number of weeks.", "error", []);
		}

		$scope.FetchTimeSheetReport = function () {
			if ($scope.FitterId != undefined) {
				var data = {
					FitterId: $scope.FitterId,
					TimeStart: moment($scope.timeStart),
					TimeEnd: moment($scope.timeEnd)
				}
				$scope.FetchTimeSheetReportPromise = ReportService.GetTimeSheetReport(data);
				$scope.FetchTimeSheetReportPromise.then(output => {
					$scope.TimeSheetReport = output.data.TimeSheetReport;
				})
			}
		}

		$scope.ExpandTimeSheetDetails = function (input) {
			var data = {
				Name: input,
				Week: $scope.Week,
				Year: $scope.Year,
				DateStart: $scope.timeStart,
				DateEnd: $scope.timeEnd
			}
			$scope.openModal('TimeSheetReportDetailedViewController', 'views/modal-templates/TimeSheetReportDetailedView.html', data)
		}

		$scope.openModal = function (controller, template, data) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: template,
				controller: controller,
				resolve: {
					Data: function () {
						return data;
					}
				}
			});
			modalInstance.result.then(function (output) {
				if (output.Moveto == 'Next') {
					var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
					$scope.next = ($scope.Duplicates[Currentindex + 1] ? $scope.Duplicates[Currentindex + 1].Id : null);

				} else if (output.Moveto == 'Back') {
					var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
					$scope.next = ($scope.Duplicates[Currentindex - 1] ? $scope.Duplicates[Currentindex - 1].Id : null);
				}
			})
			modalInstance.result.finally(function () {
				if ($scope.next != null) {
					$scope.openDeduplication($scope.next)
					$scope.next = null;
				}
			});
		};
	}])

	.controller('TimeSheetReportDetailedViewController', ["$scope", "Data", "$rootScope", "ReportService", "NotificationService", "$modalInstance", function ($scope, Data, $rootScope, ReportService, NotificationService, $modalInstance) {

		$scope.init = function () {
			$scope.Data = Data;
			$scope.FetchWeeksReport()
		}

		$scope.Close = function () {
			$modalInstance.dismiss();
		}

		$scope.FetchWeeksReport = function () {
			var data = {
				FitterName: $scope.Data.Name,
				DateStart: $scope.Data.DateStart,
				DateEnd: $scope.Data.DateEnd
			}
			$scope.FetchIndividualTimeSheetsForReportPromise = ReportService.FetchIndividualTimeSheets(data);
			$scope.FetchIndividualTimeSheetsForReportPromise.then(function (output) {
				$scope.TimeSheetReport = output.data.TimeSheets;
			});
		}
	}])
